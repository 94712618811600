import { useCallback, useMemo, useState } from 'react';
import { Fit } from '../aisle-view/hooks/useZoomFit';
import { ZoomDirection } from '../zoom-controls/ZoomControls.model';

export function useZoomToggle(initialZoomType: Fit = { type: 'height' }) {
  const [zoomType, setZoomType] = useState<Fit>(initialZoomType);
  const handleZoomChange = useCallback((action: ZoomDirection) => {
    setZoomType(action === 'in' ? { type: 'height' } : { type: 'width' });
  }, []);
  return useMemo(
    () => ({
      zoomType,
      handleZoomChange,
    }),
    [zoomType, handleZoomChange],
  );
}

import { CameraControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import CameraControlsLib from 'camera-controls';
import { RefObject, useEffect } from 'react';
import { useZoomClamp } from 'shared/map-3d/aisle-view/hooks/useZoomClamp';
import { Group } from 'three';

export const TopViewScene = ({ topViewRef }: { topViewRef: RefObject<Group> }) => {
  useZoomClamp(topViewRef, { padding: 10 });

  const controls = useThree((s) => s.controls);

  useEffect(() => {
    if (controls instanceof CameraControlsLib && topViewRef.current) {
      controls.setLookAt(0, 0, 50, 0, 0, 0, false);
      controls.fitToBox(topViewRef.current, false, {
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
      });
    }
  }, [controls, topViewRef]);
  return (
    <CameraControls
      boundaryEnclosesCamera
      makeDefault
      verticalDragToForward
      smoothTime={0.75}
      mouseButtons={{
        left: CameraControlsLib.ACTION.TRUCK,
        right: CameraControlsLib.ACTION.ROTATE,
        middle: CameraControlsLib.ACTION.NONE,
        wheel: CameraControlsLib.ACTION.ZOOM,
      }}
      minPolarAngle={-Math.PI}
      maxPolarAngle={-Math.PI}
      minAzimuthAngle={Math.PI}
      maxAzimuthAngle={Math.PI}
    />
  );
};

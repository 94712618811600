import { Bin3DContainer } from 'shared/map-3d/bin3D/Bin3D';

import { forwardRef, Ref } from 'react';
import { Group, Vector3 } from 'three';
import { useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { ActiveAisle } from '../active-aisle/ActiveAisle';
import { useRackTops } from './hooks/useRackTops';
import { TopViewMapProps } from './TopViewMap.model';

const HIT_TARGET_HEIGHT = 0.1;

type HitTargetNodeST = Omit<NodeST, 'position' | 'scale' | 'normal'> & {
  position: Vector3;
  scale: Vector3;
  normal: Vector3;
};

function useRackHitTargets({
  topZ,
  selectedFlightDomain,
}: {
  topZ: number;
  selectedFlightDomain: string;
}): HitTargetNodeST[] {
  const { systemId = '' } = useParams();
  const { data: facilityMap, isLoading: isLoadingMap } = useFacilityMap(systemId);

  if (isLoadingMap || !facilityMap) {
    return [];
  }

  const selectedFlightDomainNode = facilityMap.nodes?.find(
    (node) => node.name === selectedFlightDomain,
  );

  if (!selectedFlightDomainNode) {
    return [];
  }

  const aisleSides = findNodesByType(selectedFlightDomainNode, 'AISLE_SIDE');
  return aisleSides.map(({ position, scale, normal, ...side }) => ({
    ...side,
    position: vectorLike2Vector(position).setZ(topZ + HIT_TARGET_HEIGHT),
    scale: vectorLike2Vector(scale).setZ(0.1),
    normal: vectorLike2Vector(normal),
  }));
}

export const TopViewMap = forwardRef(
  ({ handleSelected, selectedAisleId, selectedFlightDomain }: TopViewMapProps, ref: Ref<Group>) => {
    const rackTopView = useRackTops(selectedFlightDomain);
    const hitTargets = useRackHitTargets({ topZ: rackTopView.maxHeightZ, selectedFlightDomain });

    return rackTopView.isLoading ? null : (
      <>
        {hitTargets.map((aisle) => (
          <group
            key={aisle.position.toArray().join(',')}
            visible={selectedAisleId === aisle.id}
            onPointerOver={(event) => {
              event.stopPropagation();
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleSelected(aisle);
            }}
          >
            <ActiveAisle position={aisle.position} scale={aisle.scale} opacity={0.08} />
          </group>
        ))}
        <Bin3DContainer ref={ref} bins={rackTopView.rackTops} />
      </>
    );
  },
);

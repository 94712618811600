import type CameraControlsImpl from 'camera-controls';
import { Box3, Group, Vector3, Object3DEventMap } from 'three';
import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const CAMERA_OFFSET_MULTIPLIER = 10;

function getCameraOffset(normal: Vector3 = new Vector3()) {
  return normal.clone().multiplyScalar(CAMERA_OFFSET_MULTIPLIER);
}

const FIT_PADDING = {
  paddingTop: 1,
  paddingRight: 1,
  paddingBottom: 1,
  paddingLeft: 1,
};

export interface Fit {
  type: 'width' | 'height';
}
export function useZoomFit({
  aisleRef,
  normal,
  position,
  fit,
}: {
  aisleRef: React.RefObject<Group<Object3DEventMap>>;

  normal?: Vector3;
  position?: Vector3;
  /**
   * `fit` is made to be and object instead of a primitive
   * value on purpose here. This is for fit to work even after zoom
   */
  fit?: Fit;
}) {
  const controls = useThree((state) => state.controls);
  useEffect(() => {
    if (controls && aisleRef.current) {
      const cameraControls = controls as unknown as CameraControlsImpl;

      const aisleBox = new Box3().setFromObject(aisleRef.current);
      let center = aisleBox.getCenter(new Vector3());
      const dimensions = aisleBox.getSize(new Vector3());

      if (position) {
        center = new Vector3(position.x, position.y, center.z);
      }

      const verticalBox = new Box3();
      verticalBox.setFromCenterAndSize(center, new Vector3(1, 1, dimensions.z));

      const cameraOffset = getCameraOffset(normal);

      cameraControls.setLookAt(
        // position
        center.x + cameraOffset.x,
        center.y + cameraOffset.y,
        center.z,
        // target
        center.x,
        center.y,
        center.z,
        // animate
        false,
      );

      const fitBox = fit?.type === 'width' ? aisleBox : verticalBox;

      cameraControls.setBoundary(aisleBox);
      cameraControls.fitToBox(fitBox, false, FIT_PADDING);
    }
  }, [aisleRef, controls, normal, position, fit]);
}
